import React from 'react'
import '../styles/blog.css';




const Blog = () => {
  return (
    <div>
      <h1>Coming Soon</h1>

    </div>
  );

  
}

export default Blog